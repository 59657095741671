<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card outlined elevation="0">
      <v-card-title class="headline">
        {{ $t('erp.lang_roomPlanerAddRoom') }}
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row no-gutters justify="center">
            <v-col lg="8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="roomName"
                                :label="$t('erp.lang_room')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="roomWidth"
                                :label="$t('erp.lang_roomWidth')"
                                autocomplete="off"
                                required
                                :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col class="my-0 py-0" cols="6">
                  <v-row no-gutters justify="start">
                    <v-radio-group required v-model="type" row :label="$t('erp.lang_roomType')">
                      <v-radio :label="$t('generic.lang_abstract')" value="1" ></v-radio>
                      <v-radio :label="$t('generic.lang_architect')" value="2" ></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="roomTime"
                                :label="$t('erp.lang_seatingTime')"
                                autocomplete="off"
                                required type="number" :min="1"
                                :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="tableNameFontSize"
                                :label="$t('settings.lang_fontsizeForTableName')"
                                autocomplete="off"
                                required type="number" :min="1"
                                :rules="[rules.numberRange(tableNameFontSize,10,42)]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-select 
                    outlined :items="filteredPrinters"
                    :label="$t('settings.lang_overrideInvoicePrinter')"
                    v-model="overrideInvoicePrinterId"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <v-checkbox :label="$t('generic.lang_dontAskForParties')" v-model="dontAskForParty"/>
                </v-col>
              </v-row>

              <v-col cols="12">
                <imagecropper ref="imageCropper" v-model="bgImage"  />
              </v-col>
              <v-row no-gutters justify="end">
                <v-btn color="primary"
                       :loading="loading"
                       class="ma-0"
                       x-large
                       @click="createRoom()"
                       :disabled="!valid"
                >
                  {{$t('generic.lang_add') }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
    </v-card>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns"
import Imagecropper from "@/components/common/imagecropper";
import {ENDPOINTS} from "../../../../config";
import validation from "../../../../mixins/validation/validation";
import { mapState } from "vuex";

// create/settings/room/
export default {
  name: "RoomCreateComponent",
  components:{
    Imagecropper

  },
  mixins:[mixin,validation],
  data(){
    return{
      loading:false,
      type:"1",
      valid:false,
      roomName:"",
      roomWidth:"",
      roomTime:0,
      tableNameFontSize:16,
      dontAskForParty:1,
      empty:null,
      overrideInvoicePrinterId:null,
      bgImage:null,
    }
  },
  computed:{
    ...mapState(['printer']),
    filteredPrinters() {
      return [{
        text: this.$t('generic.lang_none'),
        value: null,
      },...this.printer.printers.filter((printer) => printer.type === 1).map((printer) => {
        return {
          text: printer.name || 'no name',
          value: printer.id,
        };
      })]
    }
  },
  methods:{
    createRoom(){
      let self=this;
      if(self.$refs.form.validate()){
        self.loading=true;

        if (this.$refs.hasOwnProperty("imageCropper"))
          this.$refs.imageCropper.cropImage();
        this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.CREATE,{
          room_name:self.roomName,
          room_width:parseInt(self.roomWidth),
          roomPlanType:parseInt(self.type),
          roomTime:parseInt(self.roomTime),
          bgImage:this.bgImage,
          tableNameFontSize:this.tableNameFontSize,
          overrideInvoicePrinterId:this.overrideInvoicePrinterId,
        }).then(res=>{
          if(res.status===200){
            self.$refs.form.reset();
            Events.$emit("showSnackbar", {
              message: this.$t('erp.lang_roomPlanerSuccesfullRoomCreation'),
              color: "success"
            });
          }else{
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_unknownError'),
              color: "error"
            });
          }
          self.loading=false;
        }).catch(err=>{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_error'),
            color: "error"
          });
          self.loading=false;
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
